import Logo from './../../src/0.svg'
import innovation from './../../src/innovation.svg'
import wifi from './../../src/wifi.svg'
import React, {useRef } from 'react'
import {gsap} from 'gsap';
import Parks from './Parks'
import './Home.css'

export default function Home(){

  const tl1 = new gsap.timeline();
  let ss = useRef(null);
  const refMenu = React.useRef("")
  const refHover = React.useRef(false)
  const [menu, setMenu] = React.useState("")


  React.useEffect(() => { setMenu(refMenu) }, [])

 let handleClick=e=>{
   if(e.currentTarget.className=="parks")
    window.location.replace(e.currentTarget.getAttribute("targetURL")); 
   else
   if(e.currentTarget.id&&e.currentTarget.id=="innovation"){
     window.location.replace('http://new.bida.ub.gov.mn');
   }
 }

let handleMouseOver=e=>{
  refHover.current = true;
  setMenu(e.currentTarget.id)
}

let handleMouseEnter=e=>{
  refHover.current = true;
}

let handleMouseLeave=e=>{
  refHover.current = false
  
  setTimeout(() => {
    if(refHover.current==false)
    setMenu("")
  }, 800);
}

return(
  <>
  <div className="container">
    <div className="flex-container">
    <img ref ={ss} id='khan' src={Logo}/>
    </div>
    <div className="flex-container">
       <div id="innovation" onClick={handleClick} className="menus"><span onClick={handleClick} className="menuItem">НИЙСЛЭЛИЙН БИЗНЕС ИННОВАЦ ХӨГЖЛИЙН ГАЗАР</span></div>
    </div>
    <div className="flex-container">
        <div id="hub" className="menus"  onMouseEnter={handleMouseOver} onClick ={handleMouseOver} onMouseLeave={handleMouseLeave}><img id="#khan" src={innovation}/><span  className='smallItem'>ИННОВАЦЫН ТӨВҮҮД</span></div>
    </div>
  </div>
<Parks onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} menu={menu} onClick={handleClick}/>
  </>
)
}
