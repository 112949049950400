import React, { useEffect } from 'react'
import ga1 from '../parks/ga1.jpg'
import ga2 from '../parks/ga2.jpg'
import ga3 from '../parks/ga3.jpg'

export default function Parks(props) {

    useEffect(() => {
        console.log(props.menu);
    })
    let cont = ""

    switch (props.menu) {
        case "park": cont = <div id='ipark' className="hoverContainer" onMouseOver={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <div className="carouselBackground"></div>
            <div className="carouselContainer">
                <div className="flex-container">
                    <div className="parks" onClick={props.onClick} targetURL="http://new.bida.ub.gov.mn/item/12.html"><img id='khan' src={ga1} /><span >БАГАНУУР ҮТП</span></div>
                    <div className="parks" onClick={props.onClick} targetURL="http://new.bida.ub.gov.mn/item/14.html"><img id='khan' src={ga2} /><span >НАЛАЙХ ҮТП</span></div>
                    <div className="parks" onClick={props.onClick} targetURL="http://new.bida.ub.gov.mn/item/13.html"><img id='khan' src={ga3} /><span >ЭМЭЭЛТ ҮТП</span></div>
                </div>
            </div>
        </div>; break;
        case "hub": cont = <div id='ihub' className="hoverContainer" onMouseOver={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
            <div className="carouselBackground"></div>
            <div className="carouselContainer"><div className="flex-container">
                <div className="parks" onClick={props.onClick} targetURL="http://new.bida.ub.gov.mn/item/1.html"><img id='khan' src={ga1} /><span>Улаанбаатар инновацын төв</span></div>
            </div>
            </div>
        </div>;
            break;
        default: cont = <></>;
    }

    return (
        cont
    )
}